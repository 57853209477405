import React, {FC, useEffect, useState} from "react";
import "../src/assets/sass/style.scss";
import {AppRoutes} from "./app/routing/AppRoutes";
import {useLocation} from "react-router-dom";
import {HeaderProvider} from "./app/layout/context/HeaderContext";
import Header from "./app/layout/Header";
import Footer from "./app/layout/Footer";
import {useCookies} from "react-cookie";
import Popup from "./app/components/Popup";

type Props = {
    data ?: any
}
const App: FC<Props> = ({data}) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    const [showPopup, setShowPopup] = useState(false);
    const [cookies, setCookie] = useCookies(['newsletterPopupDisplayed', 'newsletterPopupClosed']);
    //
    useEffect(() => {
        const handleScroll = () => {
            if (!cookies.newsletterPopupDisplayed) {

                if (window.scrollY / document.body.scrollHeight > 0.5 && !cookies.newsletterPopupDisplayed && !cookies.newsletterPopupClosed) {
                    setShowPopup(true);
                    setCookie('newsletterPopupDisplayed', 'true', {path: '/', maxAge: 60 * 60 * 24 });
                }
            }
        };

        if (!cookies.newsletterPopupClosed) {
            const timer = setTimeout(() => {
                setShowPopup(true);
                setCookie('newsletterPopupDisplayed', 'true', {path: '/', maxAge: 60 * 60 * 24 });
            }, 10000); // 10 seconds

            window.addEventListener('scroll', handleScroll);

            return () => {
                clearTimeout(timer);
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [cookies.newsletterPopupDisplayed, cookies.newsletterPopupClosed, setCookie]);
    //
    //
    const handleClosePopup = () => {
        setShowPopup(false);
        setCookie('newsletterPopupClosed', 'true', {path: '/', maxAge: 60 * 60 * 24 });
    };
    return (
        <>
            <HeaderProvider>
                <Header location={location}/>
                <AppRoutes data={data}/>
                {showPopup && <Popup onClose={handleClosePopup}/>}
                <Footer location={location}/>
            </HeaderProvider>
        </>
    );
};

export default App;
