import React, {FC, useEffect, useState} from "react";
import {showCity} from "../../../core/home/requests";
import CustomPagination from "../../../components/CustomPagination";
import Select from "react-select";
import clsx from "clsx";
import ResaleCard from "../../../components/Card/ResaleCard";

type Props = {
    cities?: any
    cityFirst?: any
    selectedPage?: any
    pagination?: any
}
const Resale: FC<Props> = ({cities, cityFirst, pagination, selectedPage}) => {
    const [activeCity, setActiveCity] = useState(cityFirst)
    const [loading, setLoading] = useState(false)
    const [paginationData, setPaginationData] = useState(pagination);
    const [currentPage, setCurrentPage] = useState(selectedPage || 1);
    const defaultCity = {label: activeCity.name, value: activeCity.slug};

    const handleTabChange = (eventKey: any) => {
        setLoading(true)
        showCity(eventKey, 1, 3).then((response) => {
            if (eventKey === activeCity.slug) {
                return;
            }
            setPaginationData(response.data.city.resale_leasing.meta);
            setActiveCity(response.data.city)
            setLoading(false)
            setCurrentPage(1)
        });
    };
    const handlePageChange = (selectedItem: { selected: number }) => {
        const newPage = selectedItem.selected + 1;
        setCurrentPage(newPage);
        showCity(activeCity.slug, newPage, 3).then((response) => {
            setPaginationData(response.data.city.resale_leasing.meta);
            setActiveCity(response.data.city);
        });
    };
    useEffect(() => {
    }, [activeCity, paginationData, currentPage]);
    return (
        <div className={'box-shadow'}>
                    <div className={'bg-white bdr-t-10 ps-3 pe-3 '}>
                        <div className="row justify-content-center pb-4 align-items-center pt-4">
                            <div className="col-lg-3 ">
                                <div className={'title-main'}>
                                    Search for your property:
                                </div>
                            </div>
                            <div className={'col-lg-6 mt-2'}>
                                <div>

                                    <Select
                                        className={clsx(
                                            "text-dark w-100",
                                        )}
                                        closeMenuOnSelect={true}
                                        placeholder={"Select The Location"}
                                        options={cities.map((e: {
                                            name: any;
                                            slug: any
                                        }) => ({label: e.name, value: e.slug}))}
                                        isSearchable={true}
                                        onChange={(newValue: any) =>
                                            handleTabChange(newValue.value)
                                        }
                                        defaultValue={defaultCity}


                                    />
                                </div>
                            </div>


                        </div>

                    </div>

                    <div className={'bg-secondary-light bdr-b-10 position-relative'}>
                        <div className="row justify-content-center">
                            <div className="col-lg-10 text-center pt-4">
                                <h4 className={'title-main'}>{activeCity.name}</h4>
                                <p className={'main-description ps-2 pe-2'}>{activeCity.description}</p>


                            </div>

                            <div className="col-lg-11 mt-1">
                                <hr className={'separator'}/>
                            </div>

                            <div className="row justify-content-center mt-5 ps-lg-4 pe-lg-4">

                                {loading && (
                                    <div className="col-md-11 text-center mb-5">
                                                <span
                                                    className="indicator-progress"
                                                    style={{display: "block"}}
                                                >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span></div>
                                )}
                                {!loading && activeCity && activeCity.resale_leasing && activeCity.resale_leasing.data && activeCity.resale_leasing.data.length > 0 && activeCity.resale_leasing.data.map((item: any) => (

                                    <div className="col-lg-4 col-md-12 mb-3"

                                         key={item.id}>

                                        <ResaleCard item={item}/>

                                    </div>
                                ))}
                            </div>

                        </div>
                        <div className="resale-pagination pt-4 pb-5">
                            <CustomPagination
                                marginDisplay={0}
                                pageCount={paginationData.last_page}
                                onPageChange={handlePageChange}
                                forcePage={currentPage - 1}
                                arrowDark={true}

                            />
                        </div>

                    </div>
                </div>

    );
};

export default Resale;
