import React, {useState} from 'react';
import {toAbsoluteUrl} from "../../../helpers";


interface GalleryProps {
    images: any;
}

const ImageCarousel: React.FC<GalleryProps> = ({images}) => {
    const [loading, setLoading] = useState(images ? images.map(() => true) : []);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    };
    const handleDotClick = (index: number) => {
        setCurrentIndex(index);
    };
    const handleImageLoaded = (index: number) => {
        const updatedLoading = [...loading];
        updatedLoading[index] = false;
        setLoading(updatedLoading);
    };
    return (
        <div className="row pt-5">
            <div className={'col-lg-8 pe-lg-1'}>
                <div className={'custom-carousel position-relative'}>
                    <img
                        className="w-100 bdr-2"
                        src={toAbsoluteUrl(images[currentIndex].image)}
                        alt={`Gallery ${currentIndex + 1}`}
                        onLoad={() => handleImageLoaded(currentIndex)}
                    />


                    <button className="custom-arrow custom-arrow-prev" onClick={handlePrev}>
                        <img src="/assets/images/icons/arrow-left.png" alt="Prev"/>
                    </button>
                    <button className="custom-arrow custom-arrow-next" onClick={handleNext}>
                        <img src="/assets/images/icons/arrow-right.png" alt="Next"/>
                    </button>

                    <div className="dots-container d-lg-block d-none">
                        {images.length > 0 && images.map((_: any, index: number) => (
                            <span
                                key={index}
                                className={`dot ${currentIndex === index ? 'active' : ''}`}
                                onClick={() => handleDotClick(index)}
                            ></span>
                        ))}
                    </div>
                </div>

            </div>
            <div className={'col-lg-4 col-12 d-lg-block d-none ps-lg-1'}>
                <div className={'position-relative'}>
                    {images.length > 1 && (
                        <img
                            className="w-100 bdr-2"
                            src={toAbsoluteUrl(images[(currentIndex + 1) % images.length].image)}
                            alt={`Gallery  ${currentIndex + 2}`}
                            onLoad={() => handleImageLoaded((currentIndex + 1) % images.length)} // Handle image loaded event
                        />
                    )}

                </div>
                <div className={'mt-2'}>
                    <div className={'position-relative'}>
                        {images.length > 2 && (
                            <img
                                className="sidebar-image bdr-2"
                                src={toAbsoluteUrl(images[(currentIndex + 2) % images.length].image)}
                                alt={`Gallery  ${currentIndex + 3}`}
                                onLoad={() => handleImageLoaded((currentIndex + 2) % images.length)} // Handle image loaded event
                            />
                        )}

                    </div>

                </div>

            </div>
        </div>
    );
};

export default ImageCarousel;
