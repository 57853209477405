import { SocialMeta } from "../../components/SocialMeta";
import React, { FC, Suspense, useEffect, useState } from "react";
import { getHomeData } from "../../core/home/requests";
import TopBanner from "./components/TopBanner";
import Resale from "./components/Resale";
import Offplan from "./components/Offplan";
import Roadmap from "./components/Roadmap";
import Blog from "./components/Blog";
import Team from "./components/Team";
import About from "./components/About";
import { useHeaderContext } from "../../layout/context/HeaderContext";
import Story from "./components/Story";
import Success from "./components/Success";
import Contact from "./components/Contact";

type Props = {
    data?: any
}

const Home: FC<Props> = ({ data }) => {
    const [cities, setCities] = useState<any>(data?.cities || []);
    const [projects, setProjects] = useState<any>(data?.projects || []);
    const [subjects, setSubjects] = useState<any>(data?.subjects || []);
    const [projectFirst, setProjectFirst] = useState<any>(data?.project_mapping_first || undefined);
    const [roadmap, setRoadmap] = useState<any>(data?.roadmap?.data || []);
    const [testimonials, setTestimonials] = useState<any>(data?.testimonials || []);
    const [blog, setBlog] = useState<any>(data?.blog || []);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [selectedBlogPage, setSelectedBlogPage] = useState<number>(1);
    const [metaTags, setMetaTags] = useState<any>(data?.page || {});
    const [team, setTeam] = useState<any>(data?.team || []);
    const [paginationData, setPaginationData] = useState({
        last_page: data?.city_first?.resale_leasing?.meta?.last_page || 0,
        current_page: data?.city_first?.resale_leasing?.meta?.current_page || 0,
        total: data?.city_first?.resale_leasing?.meta?.total || 0,
    });
    const [paginationBlogData, setPaginationBlogData] = useState({
        last_page: data?.blog?.last_page || 0,
        current_page: data?.blog?.current_page || 0,
        total: data?.blog?.total || 0,
    });
    const [cityFirst, setCityFirst] = useState<any>(data?.city_first || undefined);

    useEffect(() => {
        if (!data) {
            getHomeData().then((response) => {
                setBlog(response.data.blog);
                setRoadmap(response.data.roadmap.data);
                setCities(response.data.cities);
                setProjects(response.data.projects);
                setProjectFirst(response.data.project_mapping_first);
                setCityFirst(response.data.city_first);
                setPaginationBlogData(response.data.blog);
                setSelectedPage(1);
                setSelectedBlogPage(1);
                setMetaTags(response.data.page);
                setTestimonials(response.data.testimonials);
                setPaginationData(response.data.city_first.resale_leasing.meta);
                setSubjects(response.data.subjects);
                setTeam(response.data.team);
            });
        }
    }, [data]);

    const { setVariant, setLogoColor } = useHeaderContext();

    useEffect(() => {
        setVariant("default");
        setLogoColor("light-dark");
        return () => {
            setVariant("default");
            setLogoColor("light-dark");
        };
    }, [setVariant, setLogoColor]);

    return (
        <>
            <SocialMeta title={metaTags.meta_title || "Olive Branch Properties: Real Estate Specialists in Dubai"} description={metaTags.meta_description || "Find your dream property in Dubai! Explore luxury apartments, villas, & off-plan projects. Expert agents. Contact Olive Branch Properties today!"} />
            <TopBanner title={'<div class="text-white text-center"><p class="fs-16 bolder fw-700">WELCOME TO</p> <h1 class="fs-65 mt-4 mb-lg-4">Olive Branch Properties</h1></div>'} showBtn={true} video={'/assets/olive-new-video.mp4'} />
            <About />

            <section id="resale" className={'resale position-relative mt-5'}>
                <div className={'container pb-5'}>
                    <div className="row">
                        <div className="col-lg-12 text-center mt-5 mb-4">
                            <h3 className={'title-header mt-2 pb-2'}>Resale & Leasing</h3>
                        </div>
                    </div>
                    {cities && cityFirst &&
                        <Resale cities={cities} cityFirst={cityFirst} selectedPage={selectedPage} pagination={paginationData} />}
                </div>
            </section>
            <Suspense fallback={<div></div>}>
                {projects && projectFirst && <Offplan projects={projects} projectFirst={projectFirst} />}
                {roadmap && <Roadmap roadmap={roadmap} />}
                <Story />
                {blog && blog.data && blog.data.length > 0 &&
                    <Blog blog={blog} selectedPage={selectedBlogPage} pagination={paginationBlogData} />}
                {team && team.length > 0 &&
                    <Team team={team} />}

                {testimonials && testimonials.length > 0 && <Success testimonials={testimonials} />}
                <Contact subjects={subjects} />
            </Suspense>
        </>
    );
}

export default Home;
