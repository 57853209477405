
export const scrollToSection = (id: string, location: any) => {

    const section = document.getElementById(id);
    if (location.pathname === "/" || location.pathname === "/home") {
        if (section) {
            window.scrollTo({
                top: section.offsetTop - 20, // Adjust offset as needed
                behavior: "smooth",
            });
        }
    } else {
        return "/";
    }

};

export const preloadVideos = (srcs: string[]): Promise<void[]> => {
    return Promise.all(
        srcs.map(src => {
            return new Promise<void>((resolve, reject) => {
                const video = document.createElement('video');
                video.src = src;
                video.preload = 'auto'; // Optional: Suggest to preload the video
                video.oncanplaythrough = () => resolve(); // Resolves when the video can start playing without buffering
                video.onerror = () => reject(new Error(`Failed to preload video ${src}`));
            });
        })
    );
};

export const preloadImages = (srcs: string[]): Promise<void[]> => {
    return Promise.all(
        srcs.map(src => {
            return new Promise<void>((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = () => resolve();
                img.onerror = () => reject(new Error(`Failed to preload image ${src}`));
            });
        })
    );
};
